<template>
  <div class="scan-content">
    <div class="overlay"></div>
    <div class="qr-holder">
      <div class="qr-wrapper">
        <div class="camera-prepare-mention">{{ prepared ? '就绪' : '准备中...' }}</div>
        <div class="my-scanner">
          <div class="my-scanner-left-top"></div>
          <div class="my-scanner-left-bottom"></div>
          <div class="my-scanner-right-top"></div>
          <div class="my-scanner-right-bottom"></div>
          <qrcode-stream @init="onInit" @decode="onDecode" v-if="show"></qrcode-stream>
        </div>
        <div class="scan-mention" @click="onDecode('-1')">请扫描仪器对应的二维码</div>
      </div>
    </div>
    <div class="go-back" @click="$router.back()">
      <div class="iconfont icon-prev"></div>
    </div>
  </div>
</template>

<style>
.scan-content {
  margin: 0;
}

.overlay, .qr-holder {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.overlay {
  background: #000000;
  opacity: 0.6;
}

.qr-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.my-scanner {
  position: relative;
  width: 258px !important;
  height: 258px !important;
  background: RGBA(197, 196, 199, 1.00);
  border: 1px solid #FFFFFF;
}

.my-scanner-left-top, .my-scanner-left-bottom, .my-scanner-right-top, .my-scanner-right-bottom {
  z-index: 2;
  position: absolute;
  border-style: solid;
  border-width: 0;
  border-color: #469BFF;
  width: 10px;
  height: 10px;
}

.my-scanner-left-top {
  left: 1px;
  top: 1px;
  border-left-width: 1px;
  border-top-width: 1px;
}

.my-scanner-left-bottom {
  left: 1px;
  bottom: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
}

.my-scanner-right-top {
  right: 1px;
  top: 1px;
  border-right-width: 1px;
  border-top-width: 1px;
}

.my-scanner-right-bottom {
  right: 1px;
  bottom: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
}

.scan-mention {
  height: 30px;
  background: RGBA(00, 00, 00, .7);
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-top: 19px;
}

.go-back {
  width: 30px;
  height: 30px;
  background: RGBA(00, 00, 00, .7);
  position: absolute;
  border-radius: 15px;
  left: 10px;
  top: 10px;
  z-index: 3;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.camera-prepare-mention {
  width: 100%;
  font-size: 15px;
  font-weight: 400;
  color: #FFFFFF;
  margin-bottom: 20px;
  text-align: center;
}
</style>

<script>
// https://gruhn.github.io/vue-qrcode-reader/api/QrcodeStream.html#props
import {QrcodeStream} from 'vue-qrcode-reader'
import {Toast} from "mint-ui";
import store from "@/plugins/store";

export default {
  components: {QrcodeStream},
  data() {
    return {
      prepared: false,
      show: true
    }
  },
  mounted() {
  },
  methods: {
    jumpTo(path, query) {
      this.$router.push({path: path, query: query || {}})
    },
    onDecode(equipmentId) {
      console.log("eqid1:",equipmentId)
      console.log("length:",equipmentId.indexOf("equipmentId="))
      if(equipmentId.indexOf("http")>-1){
        if(equipmentId.indexOf("equipmentId=")<0){
          Toast("二维码有误！")
          return;
        }
        equipmentId= equipmentId.substring(equipmentId.indexOf("equipmentId=")+12)
        console.log("eqid2:",equipmentId)
      }
      const that = this;
      this.$http.post(`/api/booking/getCurrentBooking?equipmentId=${equipmentId}`).then(res => {
        if (res.success) {
          let data = res.data

          if(data.isNoBooking){
            console.log("isNoBooking")
            store.commit('setCurBooking', data.noBooking)
            that.$router.push({
              path: '/confirm',
              query: {
                equipmentId: equipmentId,
                isNoBooking: true,

              }

            });
          }else {
            if (data.clientOpen){
              // that.$router.push({ path: '/exp'})
              that.$router.push({
                path: '/exp',
                query: {
                  equipmentId: equipmentId,
                  bookingId: data.id
                }
              })
            } else {
              store.commit('setCurBooking', data)
              // that.$router.push({ path: '/confirm'});
              that.$router.push({
                path: '/confirm',
                query: {
                  equipmentId: equipmentId,
                  isNoBooking: false,
                }
              });
            }
          }
        } else {
          that.show = false
          that.prepared = false
          setTimeout(function (){
            that.show = true
          }, 500)
          if (res.msg==="设备使用中"){
            that.$router.push({
              path: '/expInfo',
              query: {
                equipmentId: equipmentId,
              }
            })
          }else {
            Toast(res.msg)
          }
        }
      });
    },
    async onInit(promise) {
      // show loading indicator
      try {
        const {capabilities} = await promise
        // successfully initialized
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          // user denied camera access permisson
          Toast("请在系统设置中允许浏览器使用摄像头")
        } else if (error.name === 'NotFoundError') {
          // no suitable camera device installed
          Toast("没有可用的摄像头")
        } else if (error.name === 'NotSupportedError') {
          // page is not served over HTTPS (or localhost)
          Toast("不支持的协议")
        } else if (error.name === 'NotReadableError') {
          // maybe camera is already in use
          Toast("摄像头被占用，请重新打开页面")
        } else if (error.name === 'OverconstrainedError') {
          // did you requested the front camera although there is none?
          Toast("没有前置摄像头")
        } else if (error.name === 'StreamApiNotSupportedError') {
          // browser seems to be lacking features
          Toast("该浏览器不支持扫描操作")
        }else {
          Toast(error)
        }
      } finally {
        // hide loading indicator
        this.prepared = true;
      }
    }
  }
}
</script>
